<template>
  <KTCard :page="page">
    <template #toolbar>
      <KTAddButton :to="addRoute" v-if="!finalizado" />
    </template>
    <template #body>
      <KTTable
        :fields="insumos.columns"
        :items="insumos.data"
        :primaryKey="insumos.primaryKey"
        :sortBy="insumos.sortBy"
      >
        <template #cell(_actions)="{ item }">
          <KTViewMiniButton
            :to="getEditRoute(item.idpip_produto_insumo)"
            v-if="finalizado"
          />
          <KTEditMiniButton
            class="mr-3"
            :to="getEditRoute(item.idpip_produto_insumo)"
            v-else
          />
          <KTRemoveMiniButton
            @click="ApiRemoveItem(item.idpip_produto_insumo)"
            v-if="!finalizado"
          />
        </template>
      </KTTable>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipInsumoList",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-shopping-cart",
        title: "Materiais",
        description: `Na PAEF, Materiais consiste das diferentes matérias-primas que envolvem a
          fabricação de um produto e de seus materiais de embalagem. Em outras palavras, é
          todo material que está agregado no produto e que é empregado na sua fabricação,
          tornando-se parte dele. Clique no botão Adicionar para cadastrar uma matéria-prima
          ou material de embalagem para cada produto. Repita esse processo até completar a
          composição de cada produto. Para remover ou editar algum item use os respectivos
          botões localizados abaixo de AÇÕES.`
      },
      insumos: {
        columns: [
          {
            key: "_actions",
            label: "Ações",
            sortable: false,
            tdClass: "text-center"
          },
          {
            key: "descricao_produto",
            label: "Produto",
            sortable: true
          },
          {
            key: "discriminacao",
            label: "Discriminação",
            sortable: true
          },
          {
            key: "descricao_tipo",
            label: "Tipo",
            sortable: true,
            tdClass: "text-center"
          },
          {
            key: "nome_unidade_medida",
            label: "Unidade de Medida",
            sortable: true,
            tdClass: "text-center"
          },
          {
            key: "quantidade",
            label: "Quantidade",
            sortable: true,
            tdClass: "text-right",
            formatter: "number"
          },
          {
            key: "preco_unitario",
            label: "Preço unitário",
            sortable: true,
            tdClass: "text-right",
            formatter: "currency"
          }
        ],
        data: [],
        primaryKey: "idpip_produto_insumo",
        sortBy: "descricao_produto"
      },
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/insumo`;
    },
    manual() {
      return {
        session: "insumo",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    addRoute() {
      return {
        name: "pip_insumo_new",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.insumos.data = res[R_GETLIST].insumos;
        this.tipo_plano_investimento = res[R_GETLIST].tipo_plano_investimento;
        this.finalizado = res[R_GETLIST].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    getEditRoute(id) {
      return {
        name: "pip_insumo_edit",
        params: {
          idplano_investimento: this.idplano_investimento,
          idpip_produto_insumo: id
        }
      };
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
